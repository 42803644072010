<template>
<div>
  <v-row>
    <v-col
      cols="12"
      sm="3"
    >
      <statistics-card-with-images
        class="fill-height"
        :avatar="ratingsOptions.avatar"
        :icon="ratingsOptions.icon"
        :avatar-width="ratingsOptions.avatarWidth"
        :chip-color="ratingsOptions.chipColor"
        :chip-text="ratingsOptions.chipText"
        :statistics="totalAmountList"
        :stat-title="ratingsOptions.statTitle"
      ></statistics-card-with-images>
    </v-col>
    <v-col
      cols="12"
      sm="9"
    >
      <v-card class="fill-height">
        <v-card-title>Suchen</v-card-title>
        <v-card-text>
          <div class="d-flex align-center pb-5">
            <v-text-field
              v-model="filter.searchString"
              dense
              outlined
              hide-details
              clearable
              placeholder="Kampagne suchen"
              class="channel-list-search me-3"
            ></v-text-field>
            <v-select
              v-model="filter.angabeAktiv"
              label="Aktiv"
              outlined
              class="channel-list-search me-3"
              dense
              hide-details
              :items="filterAngabeAktivOptions"
              item-text="key"
              item-value="value"
            ></v-select>
            <inline-date-picker :date="filter.datumVon" label="Erstellt von" name="datumVon" @updateDate="updateFilter"/>
            <inline-date-picker :date="filter.datumBis" label="Erstellt bis" name="datumBis" @updateDate="updateFilter"/>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
  <v-row>
    <v-col
      cols="12"
      sm="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center flex-wrap pb-0">

          <div class="d-flex align-center pb-5">
            <v-select
              v-model="selectedMultiAction"
              label="Aktionen"
              outlined
              dense
              :items="['Export']"
              hide-details
              :disabled="Boolean(!selectedTableData.length)"
              @change="callMultiAction"
              class="invoice-list-actions me-3"
            ></v-select>

          </div>
          <v-spacer></v-spacer>
          <div class="d-flex align-center pb-5">
            <router-link :to="'/kampagne/erstellen'" >
              <v-btn
                color="secondary"
                outlined
                class="me-3"
              >
                <v-icon size="30" class="pr-2">{{icons.mdiViewDashboardVariantOutline    }}</v-icon>
                <span>Kampagne erstellen</span>
              </v-btn>
            </router-link>
            <v-btn
              color="secondary"
              outlined
              class="me-3"
              @click="exportCampaigns('all')"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiExportVariant }}
              </v-icon>
              <span>Export</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-data-table
        v-model="selectedTableData"
        :headers="headers"
        :items="listCampaigns"
        :options.sync="options"
        :server-items-length="totalAmountList"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        item-key="idNipKampagne"
        :search="filter.searchString"
        class="text-no-wrap"
        show-select
        no-data-text="Keine Einträge vorhanden"
        no-results-text="Ihre Suche ergab keinen Treffer"
        :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
      >
        <template
          v-slot:top
        >
          <v-dialog
            v-model="dialogDelete"
            width="500px"
          >
            <v-card>
              <v-card-text
                class="text-center"
              >
                Möchten Sie <strong>&nbsp;{{ deleteItem.name }}&nbsp;</strong> wirklich löschen?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="deleteCampaignConfirm"
                >
                  Löschen
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  @click="closeDelete"
                >
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.idNipKampagne`]="{item}">
          #{{ item.idNipKampagne }}
        </template>
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :class="`v-avatar-light-bg --text`"
              size="30"
            >
              <v-img
                v-if="item.nachrichtIcon"
                :src="item.images.messageIcon.url"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >I</span>
            </v-avatar>
            <div class="d-flex flex-column ms-3 pa-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.nachrichtTitel }}</span>
              <span class="text-xs">{{ item.nachrichtText }}</span>
            </div>
          </div>
        </template>
        <template #[`item.angabeAktiv`]="{item}">
          <v-chip
            @click="setCampaignState( item.idNipKampagne, !item.angabeAktiv )"
            small
            :color="item.angabeAktiv == 1 ? 'success':'secondary'"
            :class="`v-chip-light-bg ${item.angabeAktiv == 1 ? 'success':'secondary'}--text font-weight-semibold`"
          >
            {{ item.angabeAktiv == 1 ? 'Ja':'Nein' }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{item}">
          <div class="">
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <router-link :to="'/kampagne/editieren/'+item.idNipKampagne" >
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </router-link>
              </template>
              <span>Bearbeiten</span>
            </v-tooltip>
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteCampaign( item )"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Löschen</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

    </v-col>
  </v-row>
</div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiExportVariant, mdiViewDashboardVariantOutline   ,mdiPlus
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import useHelper from "@/views/useHelper";

export default {
  name: 'campaign-list',
  components: {
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const {
      downloadCSVData,
    } = useHelper()

    const ratingsOptions = {
      statTitle: 'Kampagnen',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
      icon:mdiViewDashboardVariantOutline
    }

    const selectedMultiAction = ref('')
    const selectedTableData = ref([])
    const exportCampaigns = (campaigns) => {
      axios.post('api/kampagne/', {
        aktion: 'getCampaignExportCsvString',
        campaigns: campaigns,
      })
        .then((response) => {
          if(response.data.valid){
            downloadCSVData(response.data.csvString)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const callMultiAction = (event, key) => {
      if(selectedMultiAction.value === "Export"){
        exportCampaigns(selectedTableData.value.map(item => item['idNipKampagne']));
      }
    }

    return {
      vm,
      router,
      ratingsOptions,
      selectedMultiAction,
      selectedTableData,
      callMultiAction,
      exportCampaigns,
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiExportVariant,
        mdiViewDashboardVariantOutline   ,
        mdiPlus,
      },
      filter: { searchString: '', angabeAktiv: 'all',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      filterAngabeAktivOptions: [
        { value: "all", key: 'Alle' },
        { value: "1", key: 'Ja' },
        { value: "0", key: 'Nein' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipKampagne'],
        sortDesc: [true],
      },
      headers: [
        {text: '#', align: 'start', value: 'idNipKampagne'},
        {text: 'Kampagne', value: 'name'},
        {text: 'Empfänger', value: 'stats.receiver.total'},
        {text: 'Zustellungen', value: 'stats.sent.total'},
        {text: 'Klicks', value: 'stats.clicks.total'},
        {text: 'Klickrate', value: 'stats.clicks.rate'},
        {text: 'Erstellt', value: 'zeitErstellungDE'},
        {text: 'Aktiv', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listCampaigns: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipKampagne: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipKampagne: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idNipKampagne: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchCampaigns()
  },
  watch: {
    options: {
      handler() {
        this.fetchCampaigns()
      },
    },
    filter: {
      handler() {
        this.fetchCampaigns()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Kampagne hinzufügen' : 'Kampagne bearbeiten'
    },
  },
  methods: {
    fetchCampaigns() {
      this.loading = true
      axios.post('api/kampagne/', {
        aktion: 'showOverviewCampaigns',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listCampaigns = response.data.campaigns || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setCampaignState(idNipKampagne, state) {
      axios.post('api/kampagne/', {
        aktion: 'changeCampaignState',
        idNipKampagne: idNipKampagne,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCampaigns()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteCampaign (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteCampaignConfirm () {
      axios.post('api/kampagne/', {
        aktion: 'deleteCampaign',
        mainData :{idNipKampagne: this.deleteItem.idNipKampagne},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchCampaigns()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
</style>
