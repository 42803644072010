import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"me-3",attrs:{"label":_vm.label,"hint":"d.m.Y format","dense":"","outlined":"","hide-details":"","clearable":"","persistent-hint":"","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"primary","firstDayOfWeek":"1","locale":"de-DE"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateCopy),callback:function ($$v) {_vm.dateCopy=$$v},expression:"dateCopy"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }